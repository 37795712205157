
#About{
    height: 60vh;
    flex-direction: row;  display: flex;  margin-top: 15vh; justify-content: center;
}
.lottie {
    height: 428px; width: 482px
}

.topText {
    text-align: center;
    font-size: 24px;
}
.bottomText{
    text-align: center;
    color: #999999;
    font-size: 16px;
}

@media  (max-width: 600px) {
    #About {
        flex-direction: column-reverse;  display: flex;  margin-top: 5vh;
margin-left: 20%;
padding-bottom: 80px;
padding-top: 50px;
    }
    .topText {
        text-align: center;
        font-size: 18px;
        width: 250px;
    }
    .bottomText{
        text-align: center;
        color: #999999;
        font-size: 12px;
        width: 250px;
        align-self: center;
    
    }

    .lottie {
        height: 228px; width: 282px
    }
    .footer-distributed .footer-icons a{
        display: inline-block;
        width: 35px;
        height: 35px;
        cursor: pointer;
     
        border-radius: 2px;
    
        font-size: 20px;
        color: #ffffff;
        text-align: center;
        line-height: 35px;
    
        margin-right: 3px;
        margin-bottom: 5px;
    }
}