
.features {
    background-color: #7BCDDF;
padding-bottom: 100px;
}

.screenshots {
height: 482px;
width: 224px;
border-radius: 20px;
box-shadow: 15px 15px 4px rgba(0, 0, 0, 0.20000000298023224);
}

.screenshot_section_features {

  display: flex;
  flex-direction: row;
 padding-top: 100px;
justify-content: space-evenly;

}
.qibla_section_features {

  display: flex;
  flex-direction: row;
 padding-top: 100px;
justify-content: space-evenly;

}



.text_section {
  display: flex;
  flex-direction: column;
  width: 400px;

margin-bottom: 50px;
}

.title_features {
  font-size: 48px;
}
.paragraph_features {
  font-size: 18px;
  color: white;
}

@media  (max-width: 600px) {

  .text_section {
    display: flex;
    flex-direction: column;
    width: 400px;
  margin-bottom: 20px;
  }
  
  .title_features {
    font-size: 30px;
  }
  .paragraph_features {
    font-size: 12px;
    color: white;
    padding-right: 30px;
    padding-left: 30px;
  }
  .screenshots {
    height: 330px;
    width: 160px;
    border-radius: 20px;
    box-shadow: 15px 15px 4px rgba(0, 0, 0, 0.20000000298023224);
    }
    .screenshot_section_features {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 100px;
        justify-content: space-evenly;
        text-align: center;
      
    }
    .qibla_section_features {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      padding-top: 0px;
      justify-content: space-evenly;
      text-align: center;
      padding-top: 100px;
  }
}