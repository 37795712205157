.main {
    height: 100vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size:  cover;
}
.rectangle {
    width: 360px;
}
.section {
    align-self: center;
    margin-left: 30%;
    top: 30%;
    position: absolute;
    flex-direction: column;

}

.text{
    color: white;
    text-align: center;
    align-self: center;
    justify-self: center;
    font-size: 40px;
}

.sub-text{
    color: white;
    text-align: center;
    font-size: 25px;
}

.btn--landing {
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: Righteous_400Regular;
    color: #ffffff;
    font-size: 20px;
    background: #1052a3;
    padding: 21px 20px 21px 20px;
    text-decoration: none;
    border: none;
    align-self: center;
    margin-left: 90px;
    margin-top: 10px;
}

.btn--landing:hover {
    background: #3cb0fd;
    background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
    background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
    background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
    text-decoration: none;
}

.custom-shape-divider-bottom-1663423035 {
    position: absolute;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1663423035 svg {
    position: relative;
    display: block;
    width: calc(108% + 1.3px);
    height: 70px;
}

.custom-shape-divider-bottom-1663423035 .shape-fill {
    fill: #ffffff;
}

.head-text{
    position: relative;
    color: white;

}

.center__text{
    position: absolute;
    top: 40%;
    left: 60%;
    transform: translate(-50%, -50%);
   

}
.title-top{
    font-size: 50px;  text-align: center; 
    margin-top: 15px;
}
.title-bottom{
       
    font-size: 50px; width: 400; text-align: center;
margin-top: 15px;
    

}
@media  (max-width: 880px) {
    .center__text{
        position: absolute;
        top: 45%;
        left: 60%;
        transform: translate(-50%, -50%);

    
    }
    .title-top{
        font-size: 35px;
         width: 400; 
        text-align: center;
    margin-left: 110px;
       
    }
    .title-bottom{
       
            font-size: 35px; 
            width: 400; 
          
            margin-left: 110px;
   
    }
    .main {
        height: 100vh;
        width: 100vw;
        background-size:  cover;
    }
    .section {
        overflow-x: hidden;
        justify-content: flex-start;
        margin-left: 15%;
    }

    .text{
        color: white;
        text-align: center;
        align-self: center;
        justify-self: center;
        font-size: 30px;
    }
    .rectangle {
        width: 270px;
    }
    .btn--landing {
        -webkit-border-radius: 10;
        -moz-border-radius: 10;
        border-radius: 10px;
        font-family: Righteous_400Regular;
        color: #ffffff;
        font-size: 20px;
        background: #1052a3;
        padding: 21px 20px 21px 20px;
        text-decoration: none;
        border: none;
        align-self: center; 
      margin-left: 50px;
    }
}
