

.content {
   
    justify-content: center;
    background-color: #f4f6ea;
    align-content: center;
    align-items: center;
    margin: auto;
  width: 100%;
 overflow-x: hidden;
  
   
}
.inner {
  align-content: center;
justify-content: center;
}
.title {
    text-align: center;


font-size: 50px;

}

.subtitle {
margin: 40px;
   
    font-size: 30px;
}

.paragraph {
    line-height: 2;
    margin: 40px;
}

