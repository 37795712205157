

 .nav__hamburger {
    display: none;
    cursor: pointer;
    padding: 0.8em 1.4em;
    font-weight: 500;
    font-size: 0.9rem;
    border: none;
    text-transform: lowercase;
    transition: transform 0.2s ease-in-out;
    background-color: transparent;
}

.nav__theme {
    margin-top: 0.4em;
}

.nav__list-item{
    color: white;
    font-family: Righteous_400Regular;
    font-size: 20px;
    margin-right: 20px;

    text-decoration: none;
}
.nav__list{
    justify-content: center;
    align-items: center;

    margin-left: 30px;

    display: flex;
}
.center {
    display: flex;
    align-items: center;
}

.btn {
    display: block;
    cursor: pointer;
    padding: 0.8em 1.4em;
    font-weight: 500;
    font-size: 0.9rem;
    border: none;
    text-transform: lowercase;
    transition: transform 0.2s ease-in-out;
    background-color: transparent;

}

.btn--icon {
    padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
    color: white;
}

.btn--icon:active {
    transform: translateY(-5px);
}
.link {
    color: white;
    padding: 0 0 0.3em 0;
    position: relative;
}

.link:hover {
    color: white;
}

.link::before {
    content: "";
    display: inline;
    width: 0%;
    height: 0.2em;
    position: absolute;
    bottom: 0;
    background-color: white;
    transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
    width: 100%;
}

.link--nav {
    color: white;
    text-transform: lowercase;
    font-weight: 500;
}

@media (max-width: 880px) {
    .nav__list {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;

        z-index: 2;
        background-color: #f4f6ea;
    }
    .link--nav {
        color: black;
        text-transform: lowercase;
        font-weight: 500;
    }
    

    .nav__list-item {
        margin: 0.5em 0;
    }

 .nav__hamburger {
        display: flex;
        z-index: 2;
        margin-left: 0.8em;
    }


}